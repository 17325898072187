import { FC, useState, PropsWithChildren } from 'react';
import CardsControls from '../cards-controls/CardsControls';
import styles from './SectionTitleMenu.module.scss';

interface ISectionTitleProps {
  extraClassName?: string;
}

const SectionTitleMenu: FC<PropsWithChildren<ISectionTitleProps>> = ({ extraClassName, children }) => {
  const [isCardsColumn, setIsCardsColumn] = useState(false);

  return (
    <div className={extraClassName ? `${styles.sectionTitleMenu} ${styles[`${extraClassName}`]}` : `${styles.sectionTitleMenu}`} data-btn-cards={isCardsColumn ? 'tiles' : 'rows'}>
      {children}
      <CardsControls setIsCardsColumn={setIsCardsColumn} />
    </div>
  );
};

export default SectionTitleMenu;
